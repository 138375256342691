import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import { useIsomorphicEffect } from "../hooks/useIsomorphicEffect";

import * as Sentry from "@sentry/browser";
import { getAuth, User } from "firebase/auth";
import { ApiProvider } from "./ApiProvider/ApiProvider";
import axios from "axios";
import { TORRENT_API } from "../api";
import { useSnackbar } from "notistack";
import { ClarityScript } from "./ClarityScript";
import { HTMLHeader } from "../components/Layout";

const firebaseConfig = JSON.parse(
    (process.env.GATSBY_FIREBASE_CONFIG as string) || "{}",
);

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: process.env.GATSBY_SENTRY_DSN,
    });
}

export type AuthState =
    | { status: "not checked" }
    | {
          status: "out" | "checking";
          user?: User;
      }
    | { status: "in"; user: User };

export type AppState = { auth: AuthState };
const initializeFirebase = () => {
    if (typeof window === "undefined") {
        return null;
    }
    const apps = firebase.getApps();
    return apps.length === 0 ? firebase.initializeApp(firebaseConfig) : apps[0];
};

export type AppMethods = {
    signInWithEmailPassword: (email: string, password: string) => Promise<void>;
    signInWithGoogle: () => Promise<void>;
    signInWithFacebook: () => Promise<void>;
    sendVerificationEmail: () => Promise<void>;
    verifyEmailWithCode: (code: string) => Promise<void>;
    sendPasswordResetEmail: (email: string) => Promise<void>;
    resetPassword: (email: string, code: string) => Promise<void>;
    changePassword: (oldPass: string, newPass: string) => Promise<void>;
    addTorrent: (infoHash: string) => Promise<void>;
    deleteTorrent: (infoHash: string) => Promise<void>;
    fetchTorrents: () => Promise<any>;
    downloadFile: (infoHash: string, path: string) => Promise<void>;
};

const AppContext = createContext<AppState>({ auth: { status: "not checked" } });
const noop = async () => {};

const createAppMethods = () => {
    return {
        sendPasswordResetEmail: noop,
        signInWithEmailPassword: noop,
        signInWithGoogle: noop,
        signInWithFacebook: noop,
        sendVerificationEmail: noop,
        verifyEmailWithCode: noop,
        resetPassword: noop,
        changePassword: noop,
        addTorrent: noop,
        deleteTorrent: noop,
        downloadFile: noop,
        fetchTorrents: noop as any,
    };
};
const AppMethodsContext = createContext<AppMethods>(createAppMethods());

const useRefState = <T extends any>(input: T) => {
    const [state, setState] = useState<T>(input);
    const stateRef = useRef<T>(input);
    const getState = () => {
        return stateRef.current;
    };
    const newSetState: typeof setState = (arg) => {
        setState((old) => {
            if (typeof arg === "function") {
                // @ts-ignore
                const res = arg(old);
                stateRef.current = res;
                return res;
            } else {
                stateRef.current = arg;
                return arg;
            }
        });
    };
    return [state, newSetState, getState] as const;
};

export const AppStateProvider: React.FC<{ children: React.ReactNode }> = (
    props,
) => {
    const [appState, setAppState, getAppState] = useRefState<AppState>({
        auth: {
            status: "not checked",
        },
    });

    useIsomorphicEffect(() => {
        initializeFirebase();

        getAuth().onAuthStateChanged((user) => {
            console.log(user, "auth stated updated");
            if (user) {
                setAppState((old) => ({
                    ...old,
                    auth: { status: "in", user },
                }));
            } else {
                setAppState((old) => ({ ...old, auth: { status: "out" } }));
            }
        });
        getAuth().onIdTokenChanged((user) => {
            console.timeStamp("id token updated");
        });
    }, []);

    const getAuthHeaders = async () => {
        const { auth } = getAppState();
        if (auth.status === "in") {
            return {
                Authorization: "Bearer " + (await auth.user.getIdToken()),
            };
        }
        return {};
    };
    const api = useMemo(() => {
        return axios.create({
            baseURL: TORRENT_API,
        });
    }, []);

    const fetchTorrents = useCallback(async () => {
        const resp = await api.get("/api/v1/torrent", {
            headers: await getAuthHeaders(),
        });
        return resp.data;
    }, []);

    const checkTorrent = async (torrentLink: string | File) => {
        const form = new FormData();
        if (typeof torrentLink === "string") {
            form.append("torrentUrl", torrentLink);
        } else {
            form.append("torrentFile", torrentLink);
        }
        const checkResp = await api.post("/api/v1/torrent/check", form);
        return checkResp.data;
    };
    const snackbar = useSnackbar();
    const addTorrent = useCallback(async (torrentLink: string | File) => {
        if (typeof torrentLink !== "string") {
            if (torrentLink.size > 5000000) {
                return snackbar.enqueueSnackbar(
                    "Torrent file should be less than 5mb",
                    { variant: "info" },
                );
            }
            if (!torrentLink.name.includes("torrent")) {
                return snackbar.enqueueSnackbar(
                    "You can only add torrent files",
                    { variant: "info" },
                );
            }
        }
        let checkResp;
        const id1 = snackbar.enqueueSnackbar("Checking Torrent", {
            variant: "info",
            persist: true,
        });
        try {
            checkResp = await checkTorrent(torrentLink);
        } catch (e: any) {
            snackbar.enqueueSnackbar(
                e?.data?.msg || "Some went wrong Please Try Again Later",
                { variant: "error" },
            );
            return;
        } finally {
            snackbar.closeSnackbar(id1);
        }
        const { auth: authState } = getAppState();
        if (!(authState.status === "in")) {
            return snackbar.enqueueSnackbar(
                "Please Sign in To Start A Torrent Download",
                { variant: "error" },
            );
        }
        const id2 = snackbar.enqueueSnackbar("Adding Torrent file To Account", {
            variant: "info",
            persist: true,
        });
        try {
            const resp = await api.post(
                "/api/v1/torrent",
                { infoHash: checkResp.info_hash },
                {
                    headers: await getAuthHeaders(),
                },
            );
            return resp.data;
        } catch (e: any) {
            snackbar.enqueueSnackbar(
                e?.data?.msg || "Some went wrong Please Try Again Later",
                { variant: "error" },
            );
            return;
        } finally {
            snackbar.closeSnackbar(id2);
        }
    }, []);

    const deleteTorrent = async (id: string) => {
        const id2 = snackbar.enqueueSnackbar("Deleting Torrent", {
            variant: "info",
            persist: true,
        });
        try {
            const resp = await api.delete("/api/v1/torrent/" + id, {
                headers: await getAuthHeaders(),
            });
            return resp.data;
        } catch (e: any) {
            snackbar.enqueueSnackbar(
                e?.data?.msg || "Some went wrong Please Try Again Later",
                { variant: "error" },
            );
            return;
        } finally {
            snackbar.closeSnackbar(id2);
        }
    };

    const appMethods = useMemo(() => {
        return {
            ...createAppMethods(),
            fetchTorrents,
            addTorrent,
            deleteTorrent,
        };
    }, []);

    return (
        <ApiProvider>
            <HTMLHeader />
            <AppMethodsContext.Provider value={appMethods}>
                <AppContext.Provider value={appState}>
                    <ClarityScript />

                    {props.children}
                </AppContext.Provider>
            </AppMethodsContext.Provider>
        </ApiProvider>
    );
};

export const useAppState = () => useContext(AppContext);
export const useAppMethods = () => useContext(AppMethodsContext);
