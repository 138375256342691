import { DOMAttributes } from "react";
import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { PURPLE_COLOR } from "../colors";

const StyledLink = styled.a`
    font-weight: 500;
    color: #161c2d;
    font-size: 15px;
    transition: color 0.2s linear;
    text-decoration: none;
    &:hover,
    &.active {
        color: ${PURPLE_COLOR};
    }
    &:not(:last-child) {
        margin-right: 40px;
    }
`;

const StyledGatsbyLink = StyledLink.withComponent(Link);

export const MenuLink: React.FC<{
    children: string;
    href?: string;
    onClick?: DOMAttributes<HTMLAnchorElement>["onClick"];
}> = (props) => {
    if (props.href) {
        return (
            <StyledGatsbyLink
                to={props.href}
                activeClassName="active"
                onClick={props.onClick}
            >
                {props.children}
            </StyledGatsbyLink>
        );
    }

    return (
        <StyledLink href="#" onClick={props.onClick}>
            {props.children}
        </StyledLink>
    );
};
