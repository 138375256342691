import React from "react";
import Axios from "axios";

import { Apicontext } from "./Apicontext";

const API_URL = process.env.GATSBY_API_URL;

export class ApiProvider extends React.PureComponent<{
    children: React.ReactNode;
}> {
    state = {
        api: Axios.create({ baseURL: API_URL }),
    };
    constructor(props: { children: React.ReactNode }) {
        super(props);
        this.state.api = Axios.create({ baseURL: API_URL });
    }

    render() {
        return (
            <Apicontext.Provider value={this.state.api}>
                {this.props.children}
            </Apicontext.Provider>
        );
    }
}
