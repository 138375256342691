import { Logo } from "./Logo";
import { FooterLink } from "./FooterLink";
import React, { memo } from "react";
import { PURPLE_COLOR } from "../colors";
import styled from "@emotion/styled";
import { Container, Grid2 } from "@mui/material";
import { Description } from "./Description";

const FooterElement = styled.footer`
    border-top: 1px solid #e7e9ed;
    padding-top: 130px;
    padding-bottom: 130px;
`;

export const Footer = memo(() => {
    return (
        <FooterElement>
            <Container>
                <Grid2 container>
                    <Grid2
                        size={{ xs: 12, sm: 3 }}
                        alignItems="flex-start"
                        flexDirection="column"
                    >
                        <Logo />
                        <Description>
                            Manage your downloads effortlessly—secure,
                            accessible, and always in sync.
                        </Description>
                    </Grid2>
                    <Grid2
                        offset={{ sm: 4 }}
                        flexDirection="column"
                        size={{ sm: 2, xs: 12 }}
                    >
                        <Description style={{ marginBottom: 15 }}>
                            About
                        </Description>
                        <FooterLink href="/about-us">About Us</FooterLink>
                        <FooterLink href="/contact-us">Contact Us</FooterLink>
                        <FooterLink href="/pricing">Pricing</FooterLink>
                        <FooterLink href="/blog">Blog</FooterLink>
                    </Grid2>
                    <Grid2 flexDirection="column" size={{ sm: 2, xs: 12 }}>
                        <Description style={{ marginBottom: 15 }}>
                            Info
                        </Description>
                        <FooterLink href="/faq">FAQ</FooterLink>
                        <FooterLink href="/privacy-policy">
                            Privacy Policy
                        </FooterLink>
                        <FooterLink href="/terms">Terms of Use</FooterLink>
                        <FooterLink href="/cancellation">
                            Cancellation
                        </FooterLink>
                    </Grid2>
                </Grid2>
            </Container>
        </FooterElement>
    );
});
