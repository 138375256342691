import * as React from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { BODY_BACKGROUND_COLOR } from "../colors";

import { css, Global } from "@emotion/react";
import { useAppState } from "../providers/AppStateProvider";

type LayoutProps = {
    noHeader?: boolean;
    noFooter?: boolean;
    headerStyle?: React.CSSProperties;
    headerClass?: string;
    bgColor?: string;
    children: React.ReactNode;
};
export const useLoader = () => {
    const { auth: authState } = useAppState();
    return authState.status === "checking";
};

export const HTMLHeader = React.memo(() => {
    return (
        <>
            <Global
                styles={css`
                    body {
                        background-color: ${BODY_BACKGROUND_COLOR} !important;
                        font-family: Inter, ui-sans-serif, system-ui, sans-serif,
                            "Apple Color Emoji", "Segoe UI Emoji",
                            Segoe UI Symbol, "Noto Color Emoji";
                        margin: 0;
                        padding: 0;
                        color: #161c2d;
                    }
                    body button:focus {
                        outline: initial;
                    }
                    a:hover {
                        text-decoration: none !important;
                    }

                    /* Box sizing rules */
                    *,
                    *::before,
                    *::after {
                        box-sizing: border-box;
                    }

                    /* Prevent font size inflation */
                    html {
                        -moz-text-size-adjust: none;
                        -webkit-text-size-adjust: none;
                        text-size-adjust: none;
                    }

                    /* Remove default margin in favour of better control in authored CSS */
                    body,
                    h1,
                    h2,
                    h3,
                    h4,
                    p,
                    figure,
                    blockquote,
                    dl,
                    dd {
                        margin-block-start: 0;
                        margin-block-end: 0;
                    }

                    /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
                    ul[role="list"],
                    ol[role="list"] {
                        list-style: none;
                    }

                    /* Set core body defaults */
                    body {
                        min-height: 100vh;
                        line-height: 1.3;
                    }

                    /* Set shorter line heights on headings and interactive elements */
                    h1,
                    h2,
                    h3,
                    h4,
                    button,
                    input,
                    label {
                        line-height: 1.1;
                    }

                    /* Balance text wrapping on headings */
                    h1,
                    h2,
                    h3,
                    h4 {
                        text-wrap: balance;
                    }

                    /* A elements that don't have a class get default styles */
                    a:not([class]) {
                        text-decoration-skip-ink: auto;
                        color: currentColor;
                    }

                    /* Make images easier to work with */
                    img,
                    picture {
                        max-width: 100%;
                        display: block;
                    }

                    /* Inherit fonts for inputs and buttons */
                    input,
                    button,
                    textarea,
                    select {
                        font-family: inherit;
                        font-size: inherit;
                    }

                    /* Make sure textareas without a rows attribute are not tiny */
                    textarea:not([rows]) {
                        min-height: 10em;
                    }

                    /* Anything that has been anchored to should have extra scroll margin */
                    :target {
                        scroll-margin-block: 5ex;
                    }
                `}
            />
        </>
    );
});

export const Layout: React.FunctionComponent<LayoutProps> = ({
    children,
    headerStyle,
    headerClass,
    noFooter,
    noHeader,
    bgColor = "transparent",
}) => {
    return (
        <div style={{ backgroundColor: bgColor }}>
            {!noHeader && (
                <Header style={headerStyle} className={headerClass} />
            )}
            {children}
            {!noFooter && <Footer />}
        </div>
    );
};
