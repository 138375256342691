import React from "react";

// import LogRocket from "logrocket";
import { Dialog, DialogContent } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { sendEvent } from "../helpers/sendEvent";

export class ErrorBoundary extends React.Component<
    { children: React.ReactNode },
    { error: any }
> {
    constructor(props: any) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({ error });
        if (process.env.GATSBY_BRANCH === "master") {
            Sentry.withScope((scope) => {
                scope.setExtras(errorInfo);
                Sentry.captureException(error);
            });
            sendEvent(error);
            // LogRocket.captureException(error);
        }
    }

    render() {
        return (
            <>
                <Dialog open={this.state.error}>
                    <DialogContent>
                        Oops something went. Please Refresh your page. Or Come
                        Back Later.
                    </DialogContent>
                </Dialog>
                {this.props.children}
            </>
        );
    }
}
