import { PURPLE_COLOR, WHITE_COLOR } from "../colors";
import React from "react";
import { Link } from "gatsby";
import clsx from "clsx";
import styled from "@emotion/styled";
type ButtonProps = {
    children: React.ReactNode;
    variant: "white" | "purple";
    className?: string;
    disabled?: boolean;
    href?: string;
    // inline?: boolean;
    large?: boolean;
    onClick?: (e: React.MouseEvent) => void;
};

const ButtonVariantStyle: Record<ButtonProps["variant"], React.CSSProperties> =
    {
        purple: {
            backgroundColor: PURPLE_COLOR,
            color: WHITE_COLOR,
        },
        white: {
            backgroundColor: WHITE_COLOR,
            color: PURPLE_COLOR,
        },
    };

const getBtnStyles = (props: ButtonProps) => ({
    backgroundColor: ButtonVariantStyle[props.variant].backgroundColor,
    color: ButtonVariantStyle[props.variant].color,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 500,
    padding: "12px 35px",
    ...(props.large ? { paddingTop: 16, paddingBottom: 16 } : {}),
    // ...(props.inline && { display: "inline-block" }),
    display: "inline-flex",
    alignItems: "center",
    border: 0,
    cursor: "pointer",
    transition: "opacity 0.2s linear",
    "&[disabled]": {
        color: ButtonVariantStyle[props.variant].color,
        opacity: 0.65,
        cursor: "initial",
    },

    "&[disabled]:hover": {
        color: ButtonVariantStyle[props.variant].color,
        opacity: 0.65,
        cursor: "initial",
    },
    "&:hover": {
        color: ButtonVariantStyle[props.variant].color,
        opacity: 0.9,
    },
    "&:focus": {
        boxShadow: "initial",
    },
});

const StyledLink = styled(Link)(getBtnStyles);

const StyledButton = styled("button")(getBtnStyles);

export const Button: React.FC<ButtonProps> = (props) => {
    if (props.href) {
        return (
            <StyledLink
                to={props.href}
                disabled={props.disabled}
                variant={props.variant}
                className={props.className}
                large={props.large}
            >
                {props.children}
            </StyledLink>
        );
    }
    return (
        <StyledButton
            onClick={props.onClick}
            disabled={props.disabled}
            variant={props.variant}
            large={props.large}
            className={props.className}
        >
            {props.children}
        </StyledButton>
    );
};
