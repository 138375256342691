import { Script } from "gatsby";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { AppStateProvider } from "./AppStateProvider";
import React from "react";

export const RootProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    return (
        <ErrorBoundary>
            <SnackbarProvider autoHideDuration={2000}>
                <AppStateProvider>{children}</AppStateProvider>
            </SnackbarProvider>
        </ErrorBoundary>
    );
};
