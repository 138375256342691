import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import clsx from "clsx";
import { FOOTER_LINK_COLOR, PURPLE_COLOR, SKY_BLUE_COLOR } from "../colors";

const FooterLinkStyled = styled.a`
    font-size: 17px;
    display: flex;
    color: ${FOOTER_LINK_COLOR};
    font-weight: 500;
    line-height: 1.36;
    transition: color 0.2s linear;
    text-decoration: none;
    margin-bottom: 10px;
    &:hover {
        color: ${PURPLE_COLOR};
    }
`;

const FooterGatsbyLink = FooterLinkStyled.withComponent(Link);

export const FooterLink: React.FC<{
    children: string;
    href?: string;
    redirect?: boolean;
}> = (props) => {
    const cssclass = clsx("text-decoration-none d-flex mb-3");
    if (!props.href || props.redirect) {
        return (
            <FooterLinkStyled href={props.href || "#"} className={cssclass}>
                {props.children}
            </FooterLinkStyled>
        );
    }
    return (
        <FooterGatsbyLink to={props.href} className={cssclass}>
            {props.children}
        </FooterGatsbyLink>
    );
};
