import { Button } from "./Button";
import { Logo, cond } from "./Logo";
import { MenuLink } from "./MenuLink";
import React, { memo } from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useAppState } from "../providers/AppStateProvider";
import { DARK_BLUE_BACKGROUND_COLOR } from "../colors";

import { Container, Grid2 } from "@mui/material";
import { theme } from "../theme";

const dynamicStyle = (props: { fixed?: boolean }) => css`
    position: ${cond(props.fixed, ["fixed", "relative"])};
`;

const StyledHeader = styled.header`
    min-height: 94px;
`;

const Navigation = styled.nav`
    display: flex;
    padding: 24px 0;
    justify-content: space-between;
    align-items: center;
`;

const Menu = styled.div`
    display: none;
    ${theme.breakpoints.up("sm")} {
        display: flex;
    }
`;

const CTAButton = styled(Button)`
    display: none;
    ${theme.breakpoints.up("sm")} {
        display: inline-block;
    }
`;

export const Header: React.FC<{
    style?: React.CSSProperties;
    className?: string;
    fixed?: boolean;
}> = memo((props) => {
    const { auth: authState } = useAppState();
    const LoginBtn = React.useMemo(() => {
        if (authState.status !== "in") {
            return (
                <Button variant="white" href="/signup">
                    Sign Up
                </Button>
            );
        }
        return (
            <Button href="/account" variant="white">
                My Account
            </Button>
        );
    }, [authState.status]);
    return (
        <StyledHeader className={props.className} style={props.style}>
            <Container>
                <Grid2 container>
                    <Grid2 size={12}>
                        <Navigation>
                            <Logo />
                            <Menu>
                                <MenuLink href="">Features</MenuLink>
                                <MenuLink href="/pricing">Pricing</MenuLink>
                                <MenuLink href="/contact-us">Contact</MenuLink>
                            </Menu>
                            <CTAButton variant="purple">Get Started</CTAButton>
                        </Navigation>
                    </Grid2>
                </Grid2>
            </Container>
        </StyledHeader>
    );
});
