import React from "react";
import { RootProvider } from "./src/providers/RootProvider";
export const wrapRootElement = ({ element }) => {
    return <RootProvider>{element}</RootProvider>;
};

export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
        //@ts-ignore
        import(`intersection-observer`).then(() => {});
    }
};
